.dashboard-main {
  background-color: #f2f5fe;
  position: relative;
  padding: 1rem;
  min-height: 92vh;
}
.data-table-view {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .view {
    width: 49%;
    min-height: 20rem;
  }
}
.view {
  margin: 10px 0;

  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);
  text-align: center;
  padding: 10px;
}
.date-filter-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dates-filter-wrapper {
  display: flex;
  flex-direction: column;
  width: 12rem;
  padding: 0 10px;
  .form-group {
    margin-bottom: 10px;
    .btn {
      display: block;
      margin: auto;
    }
  }
}
.chart-wrapper {
  display: flex;
  justify-content: space-between;

  .card-wrapper {
    margin-left: 1rem;
    .custom-card {
      box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem 1rem;
      width: 15rem;
      border-radius: 8px;
      margin-bottom: 0.75rem;
      .title {
        font-size: 0.9rem;
        color: #12263f;
        font-weight: 500;
        line-height: 1.3;
        margin-bottom: 10px;
      }
      .count {
        font-size: 1rem;
        color: #12263f;
        font-weight: 600;
        line-height: 1.3;
      }
    }
  }
  .chart-view {
    width: 100%;
    .view {
      margin-top: 0px;
    }
  }
}
