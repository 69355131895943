body {
  margin: 0;
  padding: 0;
  font-family: "Cerebri Sans Book";
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #495057;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  box-sizing: border-box;
}

.p-datatable {
  .p-datatable-thead {
    > tr > th,
    table thead {
      background-color: #efefef;
      padding: 5px;
      font-size: 12px;
    }
  }
  .p-datatable-tbody {
    > tr > td {
      padding: 5px;
      font-size: 11px;
    }
  }
}
