.details-container {
  display: flex;
  flex-wrap: wrap;
  .details-col {
    flex: 1 1 25%;
    margin-bottom: 0.75rem;
    border-bottom: 1px solid #ececec;
    padding-bottom: 0.75rem;
    .label {
      font-size: 0.75rem;
      color: #808080;
      font-weight: 400;
      line-height: 1.3;
    }
    .value {
      font-size: 0.9rem;
      color: #12263f;
      font-weight: 600;
      line-height: 1.3;
    }
  }
}

.p-tabview-title {
  padding: 8px 10px;
  font-size: 14px;
}
.data-item {
  font-size: 11px;
  color: #606060;
  font-weight: 500;
  span {
    font-weight: 700;
    font-size: 12px;
    color: #000;
    margin: 0 2px;
  }
}
.hotel-restaurant-wrap {
  display: flex;
  flex-direction: column;
}
.badge_count_name {
  display: inline-flex;
  margin-left: 10px;
  border-radius: 22px;
  padding: 4px 10px 4px 5px;
  .badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border-radius: 22px;
    background-color: #12263f;
    color: #fff;
    padding: 2px 10px;
    margin-right: 5px;
  }
}

.main-wrapper-view {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  .p-datatable {
    width: 100%;
  }
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #efefef;
  border-color: #efefef;
  color: #000;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  color: #000;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  color: #000;
}
.react-date-picker {
  width: 100%;
  .react-date-picker__wrapper {
    display: flex;
    width: 100%;
    min-height: 2.5rem;
    padding: 0.5rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #12263f;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    transition: all 0.2s ease;
    letter-spacing: 0.5px;
    outline: none;
  }
}
