/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Cerebri Sans Book";
  font-style: normal;
  font-weight: normal;
  src: local("Cerebri Sans Book"),
    url("../assets/fonts/Cerebri Sans Book.woff") format("woff");
}

@font-face {
  font-family: "Cerebri Sans Book Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Cerebri Sans Book Italic"),
    url("../assets/fonts/Cerebri Sans Book Italic.woff") format("woff");
}

@font-face {
  font-family: "Cerebri Sans Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Cerebri Sans Italic"),
    url("../assets/fonts/Cerebri Sans Italic.woff") format("woff");
}

@font-face {
  font-family: "Cerebri Sans SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Cerebri Sans SemiBold"),
    url("../assets/fonts/Cerebri Sans SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Cerebri Sans SemiBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Cerebri Sans SemiBold Italic"),
    url("../assets/fonts/Cerebri Sans SemiBold Italic.woff") format("woff");
}

@font-face {
  font-family: "Cerebri Sans Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Cerebri Sans Bold"),
    url("../assets/fonts/Cerebri Sans Bold.woff") format("woff");
}

@font-face {
  font-family: "Cerebri Sans Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Cerebri Sans Bold Italic"),
    url("../assets/fonts/Cerebri Sans Bold Italic.woff") format("woff");
}

@font-face {
  font-family: "Cerebri Sans ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: local("Cerebri Sans ExtraBold"),
    url("../assets/fonts/Cerebri Sans ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Cerebri Sans ExtraBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Cerebri Sans ExtraBold Italic"),
    url("../assets/fonts/Cerebri Sans ExtraBold Italic.woff") format("woff");
}

@font-face {
  font-family: "Cerebri Sans Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("Cerebri Sans Heavy"),
    url("../assets/fonts/Cerebri Sans Heavy.woff") format("woff");
}

@import "./layout/_base";
@import "./config/customVariable";
@import "./local/local";
