a,
button,
.btn {
  outline: none !important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #12263f;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 7px 15px;
  border-radius: 8px;
  line-height: 1.5;
  cursor: pointer;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s,
    box-shadow 0.15s;

  &:focus,
  &:hover,
  &:active,
  &:visited,
  &:active {
    outline: none;
    box-shadow: none;
  }
}

.btn-sm {
  padding: 4px 7px;
  font-size: 0.65rem;
  border-radius: 4px;
}
.btn-link {
  font-size: 0.8rem;
  font-weight: 500;
  color: #3f6ad8;
}
.btn-custom {
  border: 1px solid #cfe1e8;
  font-size: 13px;
  padding: 7px 15px;
  border-radius: 8px;
  font-weight: 500;
  background: #fff;
}

.btn-primary {
  color: #fff;
  background-color: #12263f;
  border-color: #12263f;
  &:focus,
  &:hover,
  &:visited,
  &:active {
    opacity: 0.9;
    background-color: #12263f;
    border-color: #12263f;
  }
}
.btn-danger {
  color: #fff;
  background-color: #dc3444;
  border-color: #dc3444;
}
.btn-icon {
  width: 35px;
  height: 35px;
  padding: 5px;
  font-size: 1rem;
  &.btn-circle {
    border-radius: 50%;
  }
  &:focus,
  &:hover,
  &:visited,
  &:active {
    color: inherit;
    background-color: inherit;
    border-color: inherit;
  }
}
.btn-icon-primary {
  color: #fff;
  background-color: #12263f;
  border-color: #12263f;
}
.btn-icon-secondary {
  color: #12263f;
  background-color: #fff;
  border-color: #e3ebf6;
}
.btn-center {
  display: block;
  margin: auto;
}
