.login-page {
  background-color: #f9fbfd;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  .login-wrapper {
    max-width: 27rem;
    width: 100%;

    .app-logo {
      background-image: url("https://viralops.com/wp-content/uploads/2021/01/3.png");
      width: 70px;
      height: 100px;
      background-repeat: no-repeat;
      background-size: contain;
      margin: auto;
      display: block;
      margin-bottom: 1.5rem;
    }

    .login-title {
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
      font-weight: normal;
      text-align: center;
      div {
        opacity: 0.6;
      }
      span {
        font-size: 1rem;
      }
    }

    .login-box {
      border: 1px solid #e3ebf6;
      margin: 10px 0;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);
      display: flex;
      overflow: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      .form-control {
        margin-bottom: 10px;
      }
      .btn-primary {
        margin: 2rem auto 0;
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
          font-size: 0.85rem;
        }
      }
    }
  }
}
