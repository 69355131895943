.top-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px 0px;
  background-color: white;
  z-index: 4;
  position: relative;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li a {
      color: #222222;
      font-size: 0.9rem;
      font-weight: 600;
      text-decoration: none;
      line-height: 1.5;
      display: flex;
      align-items: center;
      cursor: pointer;
      i {
        margin-right: 5px;
      }
    }
  }
}

.total,
.revenue-count {
  font-size: 1rem;
  color: #12263f;
  font-weight: 600;
  line-height: 1.3;
}
